<script setup lang="ts">
import { useMainStepService } from 'share/services/main/useMainService'

const {
  isStepComplete
} = useMainStepService()

</script>

<template>
  <article class="bg-primary2 p-top-20 p-bottom-40">
    <h1 class="text-title2-b m-bottom-16 p-left-16">Quick tips to make the most of The Pond!</h1>
    <Swiper
      :slides-per-view="1.15"
      :space-between="12"
      class="swiper-slider"
    >
      <!-- step 1, 2 뒤바뀜 -->
      <SwiperSlide class="slide m-right-12">
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-bottom-20 box-border flex flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP2') ? 'border-a-gray2-1': 'border-a-primary4-1'"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP2') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP2')">
                Completed
              </template>
              <template v-else>
                Step 1
              </template>
            </mark>
            <p
              class="m-top-12"
              :class="isStepComplete('STEP2') ? 'text-body2 text-gray6': 'text-body2-b text-black'"
            >
            Try our Career Survey and get personalized job recommendations based on your aptitudes!
            </p>
          </div>
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/career/survey')"
            class="w-100-p small outline"
            v-if="isStepComplete('STEP2')"
          >
            Retry the Career Survey
          </UiButton>
          <!--          완료한경우-->
          <!--          미완료-->
          <UiButton
            @click="navigateTo('/career')"
            class="w-100-p small outline"
            v-else
          >
            Take the Career Survey Now!
          </UiButton>
          <!--          미완료-->
        </article>
      </SwiperSlide>
      <SwiperSlide class="slide m-right-12">
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-bottom-20 box-border flex flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP1') ? 'border-a-gray2-1': 'border-a-primary4-1'"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP1') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP1')">
                Completed
              </template>
              <template v-else>
                Step 2
              </template>
            </mark>
            <p
              class="m-top-12"
              :class="isStepComplete('STEP1') ? 'text-body2 text-gray6': 'text-body2-b text-black'"
            >
            Take the College Survey to receive personalized college recommendations based on your preferences!
            </p>
          </div>
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/collegesurvey')"
            class="w-100-p small outline"
            v-if="isStepComplete('STEP1')"
          >
            Retry the College Survey
          </UiButton>
          <!--          완료한경우-->
          <UiButton
            @click="navigateTo('/collegesurvey')"
            class="w-100-p small outline"
            v-else
          >
            Take the College Survey Now!
          </UiButton>
        </article>
      </SwiperSlide>
      <!--      step3 의 경우 둘다 했을때 completed 처리-->
      <SwiperSlide class="slide m-right-12">
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-bottom-20 box-border flex flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP3') ? 'border-a-gray2-1': 'border-a-primary4-1'"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP3') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP3')">
                Completed
              </template>
              <template v-else>
                Step 3
              </template>
            </mark>
            <p
              class="m-top-12"
              :class="isStepComplete('STEP3') ? 'text-body2 text-gray6': 'text-body2-b text-black'"
            >
              Find your desired colleges and careers, and add majors you're interested in into your Major List!
            </p>
          </div>
          <div>
            <UiButton
              @click="navigateTo('/college/find')"
              class="w-100-p small outline"
              :disabled="isStepComplete('STEP3')"
            >
              Go to Find a College
            </UiButton>
            <UiButton
              @click="navigateTo('/career/search')"
              class="w-100-p small outline m-top-12"
              :disabled="isStepComplete('STEP3')"
            >
              Go to Find a Career
            </UiButton>
          </div>
        </article>
      </SwiperSlide>
      <SwiperSlide class="slide m-right-12">
        <article
          class="radius-16 bg-white p-x-20 p-top-16 p-bottom-20 box-border flex flex-direction-column justify-between slide-content"
          :class="isStepComplete('STEP4') ? 'border-a-gray2-1': 'border-a-primary4-1'"
        >
          <div>
            <mark
              class="radius-200 text-caption2 text-gray6 p-x-10 p-y-4 inline-block"
              :class="isStepComplete('STEP4') ? 'bg-t1': 'border-a-t2-1'"
            >
              <template v-if="isStepComplete('STEP4')">
                Completed
              </template>
              <template v-else>
                Step 4
              </template>
            </mark>
            <p
              class="m-top-12"
              :class="isStepComplete('STEP4') ? 'text-body2 text-gray6': 'text-body2-b text-black'"
            >
              On the Roadmap page, select your Dream College & Major to receive a customized Roadmap tailored to your goals!
            </p>
          </div>
          <UiButton
            @click="navigateTo('/roadmap')"
            class="w-100-p small outline"
          >
            <template v-if="isStepComplete('STEP4')">
              Track your roadmap progress
            </template>
            <template v-else>
              Get a Personalized Roadmap!
            </template>
          </UiButton>
        </article>
      </SwiperSlide>
    </Swiper>
  </article>
</template>
<style scoped lang="scss">
.swiper-slider {
  padding-left: 16px;
  padding-right: 16px;
}
.slide {
  width: 83.33vw;
  //min-width: 340px;
  max-width: 384px;
  height: 252px;
  .slide-content {
    width: 100%;
    height: 100%;
  }
  &:last-child {
  }
}
</style>
