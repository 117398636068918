<script setup lang="ts">
import useMyCollegeService from 'share/services/college/useMyCollegeService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import { useMainRecommendedCollegeService } from 'share/services/main/useMainService'

const { isLoggedIn } = storeToRefs(useMemberSessionStore())

const {
  handleThumbnailError,
  recommendedCollegeList
} = useMainRecommendedCollegeService()

const {
  myCollegeList,
  fetchMyCollegeList,
  handleAddMyCollege,
  handleDeleteMyCollege
} = useMyCollegeService()


onMounted( () => {
  if (isLoggedIn.value) {
    fetchMyCollegeList()
  }
})
</script>

<template>
  <article v-if="recommendedCollegeList?.length > 0" class="p-top-20 p-bottom-40">
    <h1 class="text-title2-b" style="display: flex; padding: 20px 16px; align-items: flex-start; gap: 8px; align-self: stretch;">
      {{ isLoggedIn ? 'Colleges You Might Be Interested In' : 'College Exploration' }}
    </h1>
    <Swiper :slides-per-view="1.15">
      <SwiperSlide v-for="(college) in recommendedCollegeList" :key="college.ipedsCollegeId" class="slide p-left-16">
        <article @click="navigateTo(`/college/detail/${college.ipedsCollegeId}`)" class="radius-16 border-a-gray2-1 bg-white box-border" style="display: flex; padding:20px; align-items: flex-start; height: 168px; gap: 8px; align-self: stretch;">
          <div class="flex flex-direction-column" style="flex: 1 0 0;">
            <div class="college_mobile_container flex flex-direction-row" style="align-items: flex-start;">
              <div class="college_info_container flex flex-direction-column" style="flex: 1;">
                <strong class="text-body1-b text-black m-bottom-4 text-clamp-2">
                  {{ college.ipedsCollegeName }}
                </strong>
                <p class="flex items-center text-caption2 text-t4 flex-wrap m-top-8">
                  {{ college.level }}
                  <span class="flex items-center">
                    <span class="w-4 h-4 bg-t2 radius-200 inline-block m-x-4"></span>
                    {{ college.addressCity }}, {{ college.addressState }}
                  </span>
                </p>
              </div>
              <div class="thumbnail_container m-bottom-16" style="flex-shrink: 0;">
                <img :src="college.imgUrl" alt="" @error="handleThumbnailError" style="width: 64px; height: 64px; border-radius: 8px; min-width: 64px; min-height: 64px;">
              </div>
            </div>
            <div class="flex items-center justify-between">
              <UiButton v-if="!myCollegeList.find(my => my.ipedsCollegeId === college.ipedsCollegeId)" class="light-primary small w-100-p" @click.stop="handleAddMyCollege(college.ipedsCollegeId)">
                Add to College List
                <IconHeart class="m-left-8" width="16" height="16" color="#0038FF"/>
              </UiButton>
              <UiButton v-else class="light-primary small w-100-p" @click.stop="handleDeleteMyCollege(college.ipedsCollegeId)">
                Remove from College List
                <IconHeartFill class="m-left-8" width="16" height="16" color="#0038FF"/>
              </UiButton>
            </div>
          </div>
        </article>
      </SwiperSlide>
      <SwiperSlide class="slide" style="">
        <article @click="navigateTo('/college/find')" class="radius-16 m-left-12 p-x-20 p-bottom-12 border-a-primary2-2 bg-white box-border flex flex-direction-column justify-center" style="width: 62.11vw; height: 168px; flex-direction: column; justify-content: center; align-self: stretch;">
          <h2 class="text-body1-b text-primary4 m-bottom-8 flex items-end">
            <IconCollege width="32" height="32" viewBox="-2 -2 27 27" color="#99AFFF" class="m-right-8" style="margin-right: 8px;"/>
            See more Colleges!
          </h2>
          <p class="flex items-center justify-between text-caption1-b text-primary5">
            Go to College Finder
            <img src="@share/assets/images/main/main-more-primary.svg" alt="">
          </p>
        </article>
      </SwiperSlide>
    </Swiper>
  </article>
</template>

<style scoped lang="scss">
  /* Your styles go here */
</style>
