<script setup lang="ts">
</script>

<template>
    <Swiper
      :modules="[SwiperAutoplay, SwiperPagination]"
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :autoplay="{
        delay: 3000,
        pauseOnMouseEnter: true
      }"
      :pagination="{ 
      clickable: true
    }"
  >
      <!-- <SwiperSlide class="visual-slide">
        <NuxtLink to="/mentoring/find/researchprogram">
          <img
            src="/images/main/banner-img1.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide> -->
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/collegesurvey">
          <img
            src="/images/main/banner-img6.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide>
      <!-- <SwiperSlide class="visual-slide">
        <NuxtLink to="/mentoring/find">
          <img
            src="/images/main/banner-img2.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide> -->
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/extracurriculars">
          <img
            src="/images/main/banner-img3.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide>
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/roadmap">
          <img
            src="/images/main/banner-img4.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide>
      <SwiperSlide class="visual-slide">
        <NuxtLink to="/career/search">
          <img
            src="/images/main/banner-img5.svg"
            alt=""
            class="visual-slide-img"
          />
        </NuxtLink>
      </SwiperSlide>
      
    </Swiper>


</template>

<style lang="scss" scoped>
:root {
  --swiper-pagination-bottom: 40px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-theme-color: #4D73FF;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: #B8B8B8;
}
.visual-slide-img {
  width: 100%;
  height: 50%;
}
</style>