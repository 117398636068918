<script setup>
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import {
  useMainRecommendedActivityService, useMainRecommendedCareerService,
  useMainRecommendedCollegeService, useMainRecommendedExpertService,
  useMainStepService,
} from 'share/services/main/useMainService'
// import Notification from '~/components/layout/Notification.vue'

definePageMeta({
  layout: 'bottom-nav-type'
})

const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())
const fetched = ref(false)

const {
  isAllStepComplete,
  fetchMainStep
} = useMainStepService()

const {
  fetchRecommendedCollegeList
} = useMainRecommendedCollegeService()

const {
  fetchRecommendedActivityList
} = useMainRecommendedActivityService()

const {
  fetchRecommendedCareerList
} = useMainRecommendedCareerService()

const {
  fetchRecommendedExpertList
} = useMainRecommendedExpertService()

onMounted(async () => {
  if (isLoggedIn.value) {
    fetchMainStep()
  }
  await Promise.all([
    fetchRecommendedCollegeList(),
    fetchRecommendedActivityList(),
    fetchRecommendedCareerList(),
    fetchRecommendedExpertList()
  ]);
  fetched.value = true;
});
if (process.client) {
  localStorage.setItem("AccessPage", 2);
}

</script>

<template>
  <LayoutHeader sticky >
   
  </LayoutHeader>
  <!--  로그인 O-->
  <!--  로그인 X-->
  <template v-if="!isLoggedIn">
    <article class="bg-primary2 p-x-16 p-y-20">
      <h1 class="text-title2-b m-bottom-16">Achieve your future with The Pond!</h1>
      <p class="text-body2 m-bottom-12">
        Discover the perfect colleges, majors, and careers tailored to you, design a personalized roadmap for your dream college and major, and kick-start your exciting journey towards success!
      </p>
      <div class="flex items-center justify-end">
        <UiButton
          @click="navigateTo('/auth/sign-in')"
          class="small light-primary dark">
          Sign In
        </UiButton>
        <UiButton
          @click="navigateTo('/auth/sign-up')"
          class="small m-left-8">
          Sign Up
        </UiButton>
      </div>
    </article>
  </template>
  <!--  로그인 O -->
  <PageMainBannerSlide v-if="isLoggedIn"/>

  <!--  로그인 O && The Pond Step guide (완료 전) -->
  <PageMainStepGuide
    v-if="isLoggedIn && !isAllStepComplete"
  />
  <!--//로그인 O && The Pond Step guide (완료 전) -->

  <!--  로그인 O && The Pond Step guide (완료) -->
  <PageMainMyPond
    v-if="isLoggedIn && isAllStepComplete"
  />
  <!--//로그인 O && The Pond Step guide (완료) -->
  <PageMainDeadlineDisplay v-if="isLoggedIn"/>
  <PageMainRecommendedColleges />
  <PageMainRecommendedActivities />
  <PageMainRecommendedCareers />
  <!-- <PageMainRecommendedExperts /> -->
  <PageMainCollegeLogos v-if="fetched" />

</template>
<style scoped lang="scss">
</style>
