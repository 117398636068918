<script setup lang="ts">
import { useMemberProfileService } from 'share/services/member/useMemberProfileService'
import useMemberRoadmapService from 'share/services/member/useMemberRoadmapService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import _ from 'lodash'

const { memberInfo } = useMemberSessionStore()

const {
  memberProfile,
  getProfileImage,
  getGraduationDate,
  fetchMemberProfile
} = useMemberProfileService()

const {
  selectedRoadmapMemberId,
  selectedRoadmapData
} = useMemberRoadmapService()

onMounted( () => {
  fetchMemberProfile()
  selectedRoadmapMemberId.value = memberInfo.memberId
})

</script>

<template>
  <section class="rgba(255, 255, 255, 1) p-top-20 p-bottom-40 p-x-16">
    <h1 class="text-title2-b m-bottom-16">Your Progress at a Glance</h1>
    <article class="bg-white radius-16 border-a-gray2-1 p-a-20" @click="navigateTo('/mypond')">
      <header class="m-bottom-16 border-bottom-t1-1 p-bottom-16 flex items-center">
        <div class="w-80 h-80 radius-200 inline-block m-right-12 flex-none">
          <img
            :src="getProfileImage(memberProfile.imageUrl)"
            alt="" class="fit-cover w-100-p h-100-p radius-200"
          >
        </div>
        <div>
          <strong class="text-body1-b block">
            {{ memberProfile.memberName }}
          </strong>
          <p v-if="memberProfile.schoolName" class="m-top-8 text-body2 text-gray6">
            {{ memberProfile.schoolName }}
          </p>
          <p class="text-body2 text-t4">
            {{ getGraduationDate(memberProfile.graduationDate) }}
          </p>
        </div>
      </header>
      <div class="flex justify-between">
        <article
          style="width: 43.88vw" class="border-right-t2-1 p-right-12 flex flex-direction-column justify-between"
        >
          <h2 class="text-body2-b m-bottom-16">Academic status</h2>
          <div>
            <div class="flex justify-between items-center">
              <p class="text-caption2 text-gray5">Coursework<br/>completion rate</p>
              <strong class="text-title2-b text-primary5">
                {{ selectedRoadmapData.roadmapCourseWork?.completionRate || 0 }}%
              </strong>
            </div>
            <div class="flex items-center justify-center m-top-8 box-border">
              <div class="progress-line">
                <span
                  class="progress-bar"
                  :style="`width: ${selectedRoadmapData.roadmapCourseWork?.completionRate || 0}%`"
                >
                </span>
              </div>
            </div>
          </div>
        </article>
        <article
          v-if="!_.isEmpty(selectedRoadmapData?.roadmapActivityCount)"
          style="width: 29.44vw"
        >
          <h2 class="text-body2-b m-bottom-16 flex justify-between items-center">
            All
            <strong class="text-caption2-b text-primary5">
              {{
                selectedRoadmapData.roadmapActivityCount?.upcomingCnt
                + selectedRoadmapData.roadmapActivityCount?.currentCnt
                + selectedRoadmapData.roadmapActivityCount?.completedCnt
              }}
            </strong>
          </h2>
          <div>
            <div class="flex justify-between items-center">
              <span class="text-caption2 text-gray5">Unscheduled</span>
              <strong class="text-caption2-b text-primary5">
                {{ selectedRoadmapData.roadmapActivityCount?.upcomingCnt }}
              </strong>
            </div>
            <div class="flex justify-between items-center m-top-4">
              <span class="text-caption2 text-gray5">Scheduled</span>
              <strong class="text-caption2-b text-primary5">
                {{ selectedRoadmapData.roadmapActivityCount?.currentCnt }}
              </strong>
            </div>
            <div class="flex justify-between items-center m-top-4">
              <span class="text-caption2 text-gray5">Completed</span>
              <strong class="text-caption2-b text-primary5">
                {{ selectedRoadmapData.roadmapActivityCount?.completedCnt }}
              </strong>
            </div>
          </div>

        </article>
      </div>
    </article>
  </section>
</template>

<style scoped lang="scss">
@include progress(calc(100%),8px,$bg-color: $gray2, $active-bg-color: $primary5, $number-width: 0, $space: 0, $radius: 100px);

</style>
