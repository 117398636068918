import { executeApi, useApi } from '../../composables/useApi'
import { ref } from 'vue'
import { useNotify } from '../../composables/useNotify'

export default () => {
  const api = useApi()
  const { notify, alert } = useNotify()

  const activityDetail = ref({})

  // Activity 생성
  const createActivity = async (recommendedActivityId) => {
    await executeApi(async () => {
      await api.roadmapAnalysis.createAnalysisReportActivity({
        recommendedActivityId
      })
      notify.success('Successfully added to the roadmap.')
    }, (e) => {
      console.log(e.response)
      if (e.response.data.errorCode === 'DUPLICATE_ACTIVITY') {
        notify.failure(`You've already added this activity .`)
      } else {
        alert('Error.')
      }
    })
  }

  // Activity 상세 조회
  const fetchActivityDetail = async (activityId: number) => {
    await executeApi(async () => {
      const { data } = await api.main.getActivityDetail({
        recommendedActivityId: activityId
      })
      activityDetail.value = data
    })
  }

  return {
    activityDetail,
    createActivity,
    fetchActivityDetail,
  }
}
