<script setup>
import { useModal } from 'vue-final-modal'
import DetailActivityModal from '~/components/modal/main/ActivityDetail.vue'
import PremiumActivityList from '~/components/modal/main/PremiumActivityList.vue'
import { useMainRecommendedActivityService } from 'share/services/main/useMainService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import Trophy_line from 'share/components/icon/Trophy_line.vue'

const { isLoggedIn } = storeToRefs(useMemberSessionStore())

const {
  recommendedActivityList
} = useMainRecommendedActivityService()


const handleShowActivityDetailModal = (id) => {
  const { open, destroy } = useModal({
    component: DetailActivityModal,
    attrs: {
      onCancel: () => {
        destroy()
      },
      id: id
    },
  })
  open()
}

const handleShowPremiumActivityModal = (id) => {
  const { open, destroy } = useModal({
    component: PremiumActivityList,
    attrs: {
      onCancel: () => {
        destroy()
      },
      id: id
    },
  })
  open()
}

</script>

<template>
  <article v-if="recommendedActivityList?.length > 0" class="p-top-20 p-x-16 p-bottom-40">
    <h1 class="text-title2-b m-bottom-16">
      {{ isLoggedIn ? 'Nearby Extracurricular Activities for You' : 'Extracurricular Activities Directory' }}
    </h1>
    <div
      v-for="(activity) in recommendedActivityList"
      :key="activity.ipedsCollegeId"
      class="radius-16 p-a-16 border-a-gray2-1 bg-white m-bottom-12"
      @click="handleShowActivityDetailModal(activity.recommendedActivityId)"
    >
      <UiLabel class="small border blue">
        Recommended
      </UiLabel>
      <strong class="block text-body1-b m-top-12">
        {{ activity.title }}
      </strong>
    </div>
    <article
      class="border-a-primary2-1 radius-16 p-a-16 bg-white m-bottom-12"
      @click="navigateTo('/extracurriculars')"
    >
    <h2 class="text-body1-b text-primary4 m-bottom-8 flex items-end">
          <Trophy_line width="32" height="32" viewBox="-2 -2 27 32" color="#99AFFF" class="m-right-8"/>
          See more activities!
        </h2>
        <p class="flex items-center justify-between text-caption1-b text-primary5">
          Go to Activity Search
          <img src="@share/assets/images/main/main-more-primary.svg" alt="">
      </p>
    </article>
  </article>

</template>

<style scoped lang="scss">

</style>
