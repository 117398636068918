<script setup>
import useMyCareerService from 'share/services/career/useMyCareerService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import { useMainRecommendedCareerService } from 'share/services/main/useMainService'

const { isLoggedIn } = storeToRefs(useMemberSessionStore())

const { recommendedCareerList } = useMainRecommendedCareerService()

const { myCareerList, handleAddMyCareer, handleDeleteMyCareer, fetchMyCareerList } = useMyCareerService()

onMounted(() => {
  if (isLoggedIn.value) {
    fetchMyCareerList()
  }
})
</script>

<template>
  <article v-if="recommendedCareerList?.length > 0" class="p-top-20 p-bottom-40 p-left-16">
    <h1 class="text-title2-b m-bottom-16">
      {{ isLoggedIn ? 'Explore Your Dream Career Pathways' : 'Career Pathways' }}
    </h1>
    <Swiper :slides-per-view="1.15" :space-between="12">
      <SwiperSlide v-for="(c, idx) in recommendedCareerList" :key="idx" class="slide m-right-12">
        <div
          class="radius-16 p-x-20 p-top-16 p-bottom-20 border-a-gray2-1 bg-white flex flex-direction-column justify-between box-border"
          style="height: 222px"
          @click="navigateTo(`/career/detail/${c.careerId}`)"
        >
          <div>
            <strong
              class="text-body1-b"
              style="display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-height: 44px"
            >
              {{ c.careerTitle }}
            </strong>

            <div class="m-top-2">
              <div class="m-bottom-8 flex items-center">
                <IconDegree width="24" height="24" color="#747474" class="m-right-12" />
                <div>
                  <span class="text-caption2 text-t4 flex-none">Common Education Level</span>
                  <div class="">
                    <strong v-for="edu in c.eduLevel" class="text-body2-b block">
                      {{ edu.title.replace(/\s?\([^)]+\)/, '') }}
                    </strong>
                  </div>
                </div>
              </div>
              <div class="flex items-center">
                <IconDollar width="24" height="24" color="#747474" class="m-right-12" />
                <div>
                  <span class="text-caption2 text-t4 block">Average Annual Income</span>
                  <strong class="text-body2-b">
                    <template v-if="c.salary">${{ c.salary?.toLocaleString() }}</template>
                    <template v-else>$ -</template>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div>
            <UiSeparator class="bg-t1 m-bottom-8" />
            <UiButton
              v-if="!myCareerList.find((my) => my.careerId === c.careerId)"
              style="width: 100%"
              class="light-primary small"
              @click.stop="handleAddMyCareer(c.careerId)"
            >
              Add to Career List
              <IconHeart class="m-left-8" width="16" height="16" color="#0038FF" />
            </UiButton>
            <UiButton
              v-else
              style="width: 100%"
              class="light-primary small"
              @click.stop="handleDeleteMyCareer(c.careerId)"
            >
              Remove from Career List
              <IconHeartFill class="m-left-8" width="16" height="16" color="#0038FF" />
            </UiButton>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide class="slide" style="">
        <article
          class="radius-16 p-x-20 p-bottom-12 border-a-primary2-2 bg-white box-border flex flex-direction-column justify-center"
          @click="navigateTo('/career/search')"
          style="width: 61.11vw; height: 222px"
        >
          <h2 class="text-body1-b text-primary4 m-bottom-8 flex items-end">
            <IconBriefcase width="32" height="32" viewBox="0 -2 32 32" color="#99AFFF" class="m-right-8" />
            See more careers!
          </h2>
          <p class="flex items-center justify-between text-caption1-b text-primary5">
            Go to Career Finder
            <img src="@share/assets/images/main/main-more-primary.svg" alt="" />
          </p>
        </article>
      </SwiperSlide>
    </Swiper>
  </article>
</template>

<style scoped lang="scss">
//.swiper-area {
//  padding-left: 16px;
//}
//.slide {
//  width: 83.33vw;
//  &:last-child {
//    width: 61.11vw;
//  }
//}
</style>
