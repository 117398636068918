<script setup lang="ts">
import { useMemberProfileService } from 'share/services/member/useMemberProfileService'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const { memberProfile, getProfileImage, getGraduationDate, fetchMemberProfile } = useMemberProfileService()

const folded = ref(false)

onMounted(async () => {
  await fetchMemberProfile()
})

const titleText = (type: Number) => {
  switch (type) {
    case 1:
      return 'Explore Your Early Decision College Options!'
    case 2:
      return 'Explore your Early Action College Options'
    case 3:
      return 'Explore Your Regular Decision College Options!'
    case 4:
      return 'Upcoming SAT Deadline : Time is Ticking!'
    case 5:
      return 'Upcoming ACT Deadline: Time is Ticking!'
    default:
      return ''
  }
}

const subtitleText = (type: Number, days: String) => {
  switch (type) {
    case 1:
      return `Nearby colleges have an early decision deadline in D-${days}.`
    case 2:
      return `Nearby colleges have an early action deadline in D-${days}.`
    case 3:
      return `Nearby colleges have a regular decision deadline in D-${days}.`
    case 4:
      return `The deadline for the SAT is in D-${days}.`
    case 5:
      return `The deadline for the ACT is in D-${days}.`
    default:
      return ''
  }
}

const linkText = (type: Number) => {
  switch (type) {
    case 1:
    case 2:
    case 3:
      return `See Colleges now!`
    case 4:
    case 5:
      return `Visit`
    default:
      return ''
  }
}

const openLink = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    window.open(url, '_blank')
  } else {
    // Navigate internally using Vue Router
    router.push(url)
  }
}
</script>

<template>
  <!-- <div
    class="m-y-16 p-x-16 w-100-p"
    v-if="Array.isArray(memberProfile.admissionInfos) && memberProfile.admissionInfos.length > 0"
    style="box-sizing: border-box"
  >
    <div
      class="bg-primary1 radius-16 p-x-20 p-y-16 deadline-container"
      :class="{ unfolded: folded }"
      @click="folded = !folded"
      style="border: 1px solid rgba(153, 175, 255, 1)"
    >
      <div class="flex justify-start items-center">
        <div class="flex-none">
          <span class="m-right-4 text-title1">D -</span>
          <span
            v-for="numbers in memberProfile.admissionInfos[0].dday?.split('')"
            class="bg-white border-a-gray2-1 text-title1 radius-4 p-x-6 p-y-4 m-right-4"
          >
            {{ numbers }}
          </span>
        </div>

        <div class="m-left-16 text-body2-b ellipsis">
          {{ titleText(memberProfile?.admissionInfos?.[0]?.admissionId ?? 0) }}
        </div>

        <div style="margin-left: auto">
          <div class="rotate-chevron"><IconChevronUp /></div>
        </div>
      </div>
      <div class="text-body2-b m-top-16">
        {{ titleText(memberProfile?.admissionInfos?.[0]?.admissionId ?? 0) }}
      </div>
      <p class="m-top-8 text-body2 m-bottom-8">
        {{
          subtitleText(
            memberProfile?.admissionInfos?.[0]?.admissionId ?? 0,
            memberProfile?.admissionInfos?.[0]?.dday ?? '0'
          )
        }}
      </p>
      <p class="text-body2 flex items-center" style="gap: 16px">
        <span>View your options</span>
        <UiTextButton
          class="text-primary5"
          style="font-weight: bold"
          @click="openLink(memberProfile?.admissionInfos?.[0]?.linkUrl ?? '')"
          @click.stop=""
        >
          {{ linkText(memberProfile?.admissionInfos?.[0]?.admissionId ?? 0) }}
        </UiTextButton>
      </p>
    </div>
  </div> -->
</template>

<style scoped lang="scss">
.deadline-container {
  cursor: pointer;
  height: 24px;
  transition: all 0.4s ease-in-out;
  overflow: hidden;

  .rotate-chevron {
    transition: all 0.4s ease-in-out;
    transform-origin: center;
    position: relative;
    transform: rotate(180deg);
  }
  &.unfolded {
    height: 150px;
    .ellipsis {
      display: none;
    }
    .rotate-chevron {
      transform: rotate(0deg);
    }
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  display: inline-block;
  //   max-width: 150px;
  max-width: calc(100% - 100px);
}
</style>
