<script setup>
import activitiesService from 'share/services/main/useActivitiesService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { useNotify } from '@share/composables/useNotify'
import dayjs from 'dayjs'
const emit = defineEmits(['cancel','confirm','delete','edit'])

const { confirm } = useNotify()
const {isLoggedIn} = useMemberSessionStore()

const {
  createActivity,
  activityDetail,
  fetchActivityDetail
} = activitiesService()

const props = defineProps({
  id: {
    type: Number
  }
})

const showMore = ref(false)

const convertDate = (dateText) => {
  const stringDate = dayjs(dateText).toDate().toDateString().split(' ')
  return `${stringDate[2]}. ${stringDate[1]}. ${stringDate[3]}`
}

await fetchActivityDetail(props.id)

const closeModal = () => {
  emit('cancel')
}

const handleAddUpcoming = async () => {
  if (isLoggedIn.value) {
    await createActivity(activityDetail.value.recommendedActivityId);
  } else {
    navigateTo("/auth/sign-in");
  }
  closeModal();
}

useCustomBackButton(closeModal)
</script>

<template>
  <LayoutModal modal-id="ModalActivityDetail" fix-bottom is-scroll type="full" header-type="empty" :closeConfirm="false">
    <div class="full-height-top">
      <LayoutHeader type="empty" sticky>
        <template v-slot:actions>
          <h1 class="text-body1-b modal-title text-black flex items-center">
            Recommend Activity Detail
          </h1>
          <button class="header-right-icon" @click="closeModal">
            <IconClose width="24" height="24" color="#434247"/>
          </button>
        </template>
      </LayoutHeader>
      <section class="modal-content w-100-p">
        <div class="flex p-x-16 p-top-18 p-bottom-48 bg-primary1 flex-direction-column">
          <div class="flex justify-between items-center m-bottom-16">
            <UiLabel
              class="border small flex-none"
              :class="activityDetail.premiumYn === 'Y' ? 'purple': 'blue'"
            >
              <template v-if="activityDetail.premiumYn === 'Y'">
                Premium
              </template>
              <template v-else>
                Recommended
              </template>

            </UiLabel>
          </div>
          <div class="flex text-body1-b">{{ activityDetail.title }}</div>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-01 m-right-8"></span>
            <h4 class="flex text-title2-b">Activity Type</h4>
          </div>
          <div class="flex text-body2 text-gray6">{{ activityDetail.activityName }}</div>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-02 m-right-8"></span>
            <h4 class="flex text-title2-b">Memo</h4>
          </div>
          <div
            class="text-body2 text-gray6"
            :class="{'text-clamp-3': !showMore }"
          >
            {{ activityDetail.description ? activityDetail.description : 'Check website for details.' }}
          </div>
          <UiSeparator v-if="!showMore" class="m-top-16" />
          <UiTextButton
            class="m-top-12 item-align--center flex text-gray4"
            :arrow="!showMore ? 'down' : 'up'"
            @click="showMore = !showMore"
          >
            <template v-if="!showMore">
              Show More
            </template>
            <template v-else>
              Show Less
            </template>
          </UiTextButton>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-03 m-right-8"></span>
            <h4 class="flex text-title2-b">Website</h4>
          </div>
          <a class="flex text-body2 text-primary5 break-word text-underline"
             :href="activityDetail.link"
             target="_blank"
          >
            {{ activityDetail.link }}</a>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-04 m-right-8"></span>
            <h4 class="flex text-title2-b">Location</h4>
          </div>
          <div class="flex text-body2 text-gray6">{{ activityDetail.city }}{{ activityDetail.city ? ',' : '' }} {{ activityDetail.stateName }}</div>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-05 m-right-8"></span>
            <h4 class="flex text-title2-b">Duration</h4>
          </div>
          <div v-if="startDate !== null && endDate !== null" class="flex text-body2 text-gray6">
            {{ convertDate(activityDetail.startDate) }} ~ {{ convertDate(activityDetail.endDate) }}
          </div>
          <div v-else class="flex text-body2 text-gray5">
            TBD
          </div>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40 border-bottom-t1-8">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-07 m-right-8"></span>
            <h4 class="flex text-title2-b">Strength of This Activity</h4>
          </div>
          <div class="flex text-body2 text-gray6 flex-wrap flex gap-8">
            <span
              v-for="strength in  activityDetail.strengthName?.split(',')"
              class="text-caption2 border-a-t2-1 radius-16 text-gray6 activity-detail-tag"
            >{{ strength }}</span>
          </div>
        </div>
        <div class="flex flex-direction-column p-x-16 p-top-32 p-bottom-40">
          <div class="flex items-center m-bottom-12">
            <span class="flex activity-icon activity-icon-08 m-right-8"></span>
            <h4 class="flex text-title2-b">Related Courses</h4>
          </div>
          <div class="text-body2 text-gray6 flex-wrap flex gap-8">
            <span
              v-for="course in activityDetail.subjectCategoryName?.split(',')"
              class="text-caption2 border-a-t2-1 radius-16 text-gray6 activity-detail-tag inline-block"
            >
              {{ course }}</span>
          </div>
        </div>
      </section>
    </div>
    <div class="full-height-bottom">
      <div class="bg-white p-a-16 border-top-gray1-1 flex justify-between box-border m-bottom-24">
        <UiButton
          class="w-100-p"
          @click="handleAddUpcoming"
        >Add to My Activities
        </UiButton>
      </div>
    </div>
  </LayoutModal>
</template>

<style scoped lang="scss">
@include fullHeight;
  .activity-detail-tag {
    padding: 4px 10px;
  }
    .activity-icon {
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 36px;
      &-01 {
        background-image: url("@share/assets/images/roadmap/activity/icon-type.svg")
      }
      &-02 {
        background-image: url("@share/assets/images/roadmap/activity/icon-memo.svg")
      }
      &-03 {
        background-image: url("@share/assets/images/roadmap/activity/icon-site.svg")
      }
      &-04 {
        background-image: url("@share/assets/images/roadmap/activity/icon-location.svg")
      }
      &-05 {
        background-image: url("@share/assets/images/roadmap/activity/icon-duration.svg")
      }
      &-06 {
        background-image: url("@share/assets/images/roadmap/activity/icon-position.svg")
      }
      &-07 {
        background-image: url("@share/assets/images/roadmap/activity/icon-strength.svg")
      }
      &-08 {
        background-image: url("@share/assets/images/roadmap/activity/icon-courses.svg")
      }
    }
</style>
